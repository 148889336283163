import { sortArray, isEditorRole, isViewerRole, isDistributedInstance, isMasterOrDistributedInstance, isCurrentState } from '@/utils'
import { mapGetters, mapState } from 'vuex'
export const breadcrumbs = {
    data: function() {
        return {
            userProfileItems: [{ title: 'Logout' }],
            appName: '',
            height: 70,
            organization: '',
            space: 'select space',
            instance: 'select instance',
            snapshot: 'select snapshot',
            organizationSearch: '',
            spaceSearch: '',
            instanceSearch: '',
            snapshotSearch: '',
            organizationMenu: false,
            spaceMenu: false,
            instanceMenu: false,
            snapshotMenu: false
        }
    },
    methods: {
        sortArray: function() {
            return sortArray
        },
        goToDashboard: function() {
            if (this.$route.params.oid) {
                this.$router.push({ name: 'home-dashboard', params: { oid: this.$route.params.oid } })
            } else {
                this.$router.push({ name: 'home-welcome' })
            }
        },
        fetchOrgSpaces: function(orgName) {
            this.$data.organizationMenu = false
            this.$data.space = 'select space'
            const org = this.userOrgs.filter(org => org.long_id === orgName)[0]
            this.$router.push({ name: 'home-dashboard', params: { oid: org.oid } })
        },
        openSpace(spaceName, sid, iid, snid) {
            this.$data.spaceMenu = false
            this.$data.space = spaceName
            this.$router.push({ name: 'snapshot-overview', params: { oid: this.$route.params.oid, sid: sid, iid: iid, snid: snid } })
            this.$store.dispatch('showSnackBar', {
                snackBarText: `Moved to space: ${spaceName}`,
                snackBarTimeout: 5000,
                snackBarIcon: 'check_circle'
            })
        },
        openSnapshot(snapshotName, snid) {
            this.$data.snapshotMenu = false
            this.$router.push({
                name: 'snapshot-overview',
                params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: this.$route.params.iid, snid: snid }
            })
            this.$store.dispatch('showSnackBar', {
                snackBarText: `Moved to state: ${snapshotName}`,
                snackBarTimeout: 5000,
                snackBarIcon: 'check_circle'
            })
        },
        goToSnapshotTimelineView: function() {
            this.$data.snapshotMenu = false
            this.$router.push({
                name: 'instance-snapshots',
                params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: this.$route.params.iid, snid: this.$route.params.snid }
            })
        },
        spaceRole: function(role) {
            if (role) {
                if (role.toUpperCase().includes('ADMIN')) {
                    return 'SPACE ADMIN'
                } else {
                    return 'MEMBER'
                }
            } else {
                return 'UNAVAILABLE'
            }
        },
        showInstanceOpenSnackBar(instanceName) {
            this.$store.dispatch('showSnackBar', {
                snackBarText: `Moved to instance: ${instanceName}`,
                snackBarTimeout: 5000,
                snackBarIcon: 'check_circle'
            })
        },
        fetchDistributedInstanceSnapshots(iid) {
            this.$axios
                .get(`instances/${iid}/snapshots`)
                .then(response => {
                    if (response.data && response.data.length < 2) {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Distributed has no snapshots',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'info'
                        })
                    } else if (response.data && response.data.length > 1) {
                        const sortedSnapshots = sortArray(response.data, 'snid', 'descending', false)
                        const nonDevelopmentSnapshots = sortedSnapshots.filter(snapshot => snapshot.long_id !== 'Current State')
                        const mostRecentSnapshot = nonDevelopmentSnapshots[0]
                        this.$router.push({
                            name: 'snapshot-overview',
                            params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: mostRecentSnapshot.iid, snid: mostRecentSnapshot.snid }
                        })
                        this.showInstanceOpenSnackBar(mostRecentSnapshot.long_id)
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch snapshots of distributed',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
        },
        openInstance(instanceName, iid, role) {
            this.$data.instanceMenu = false
            this.$data.instanceSearch = ''
            const instanceData = this.fullInstanceSnapshotData.filter(instance => instance.iid === iid)[0]
            if (isEditorRole(role) === true && isDistributedInstance(instanceName) === false) {
                const sortedInstanceData = sortArray(instanceData.data, 'snid', 'ascending', false)
                if (sortedInstanceData.length) {
                    this.$router.push({
                        name: 'snapshot-overview',
                        params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: iid, snid: sortedInstanceData[0].snid }
                    })
                    this.showInstanceOpenSnackBar(instanceName)
                }
            } else if (isDistributedInstance(instanceName) === true) {
                this.fetchDistributedInstanceSnapshots(iid)
            } else if (isViewerRole(role) === true) {
                const sortedInstanceData = sortArray(instanceData.data, 'snid', 'descending', false)
                this.$router.push({
                    name: 'snapshot-overview',
                    params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: iid, snid: sortedInstanceData[0].snid }
                })
                this.showInstanceOpenSnackBar(instanceName)
            }
        },
        disabledInstance(snapshots) {
            if (snapshots.length === 0) {
                return true
            } else if (snapshots.length >= 1) {
                return false
            }
        }
    },
    computed: {
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isDistributedInstance']),
        ...mapState('instanceStore', ['instanceSnapshots']),
        ...mapState(['userOrgs']),
        ...mapState('orgStore', ['educationSpaces', 'researchSpaces', 'datasetSpaces', 'spacesWithPrimarySnapshots', 'fetchingSpaces']),
        ...mapState('spaceStore', ['spaceInstances', 'fetchingInstances', 'fullInstanceSnapshotData']),
        ...mapState('instanceStore', ['fetchingSnapshots']),
        ...mapGetters('snapshotStore', ['isDevelopment', 'nonDevelopmentSnapshots']),
        ...mapGetters('orgStore', ['orgLongName']),
        organizationName() {
            if (this.$route.params.oid !== undefined) {
                return this.orgLongName
            } else {
                return this.orgNames[0]
            }
        },
        instanceName() {
            if (this.$route.params.iid !== undefined && this.spaceInstances && this.spaceInstances.length) {
                return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
            } else {
                return 'select instance'
            }
        },
        spaceName() {
            if (this.$route.params.sid !== undefined && this.spacesWithPrimarySnapshots && this.spacesWithPrimarySnapshots.length) {
                return this.$store.getters['spaceStore/spaceLongNameById'](this.$route.params.sid)
            } else {
                return 'select space'
            }
        },
        snapshotName() {
            if (this.$route.params.snid !== undefined && this.instanceSnapshots && this.instanceSnapshots.length) {
                return this.$store.getters['snapshotStore/snapshotLongNameById'](this.$route.params.snid)
            } else {
                return 'select snapshot'
            }
        },
        orgNames() {
            return this.userOrgs
                .map(org => org.long_id)
                .sort(function(a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase())
                })
        },
        sortedOrgNames() {
            if (this.organizationSearch.length) {
                return sortArray(this.userOrgs, 'long_id', 'ascending', true).filter(
                    org => org.long_id.toLowerCase().indexOf(this.organizationSearch.toLowerCase()) !== -1
                )
            } else {
                return sortArray(this.userOrgs, 'long_id', 'ascending', true)
            }
        },
        sortedEducationSpaces() {
            if (this.spaceSearch.length) {
                return this.educationSpaces.filter(space => space.space_long_id.toLowerCase().indexOf(this.spaceSearch.toLowerCase()) !== -1)
            } else {
                return this.educationSpaces
            }
        },
        sortedResearchSpaces() {
            if (this.spaceSearch.length) {
                return this.researchSpaces.filter(space => space.space_long_id.toLowerCase().indexOf(this.spaceSearch.toLowerCase()) !== -1)
            } else {
                return this.researchSpaces
            }
        },
        sortedDatasetSpaces() {
            if (this.spaceSearch.length) {
                return this.datasetSpaces.filter(space => space.space_long_id.toLowerCase().indexOf(this.spaceSearch.toLowerCase()) !== -1)
            } else {
                return this.datasetSpaces
            }
        },
        sortedInstances() {
            if (this.instanceSearch.length) {
                return sortArray(this.fullInstanceSnapshotData, 'iid', 'ascending', false).filter(
                    instance => instance.instance_name.toLowerCase().indexOf(this.instanceSearch.toLowerCase()) !== -1
                )
            } else {
                const masterAndDistributed = this.fullInstanceSnapshotData.filter(instance => isMasterOrDistributedInstance(instance.instance_name) === true)
                const nonMasterAndDistributed = this.fullInstanceSnapshotData.filter(
                    instance => isMasterOrDistributedInstance(instance.instance_name) === false
                )
                const sortedNonMasterAndDistributed = sortArray(nonMasterAndDistributed, 'instance_name', 'ascending', true)
                return masterAndDistributed.concat(sortedNonMasterAndDistributed)
            }
        },
        sortedSnapshots() {
            if (this.snapshotSearch.length) {
                return sortArray(this.instanceSnapshots, 'snid', 'ascending', false).filter(
                    snapshot => isCurrentState(snapshot.short_id) === false && snapshot.long_id.toLowerCase().indexOf(this.snapshotSearch.toLowerCase()) !== -1
                )
            } else {
                return sortArray(this.instanceSnapshots, 'snid', 'ascending', false).filter(snapshot => isCurrentState(snapshot.short_id) === false)
            }
        },
        currentState() {
            if (this.snapshotSearch.length) {
                return this.instanceSnapshots.filter(
                    snapshot => snapshot.long_id === 'Current State' && snapshot.long_id.toLowerCase().indexOf(this.snapshotSearch.toLowerCase()) !== -1
                )
            } else {
                return this.instanceSnapshots.filter(snapshot => snapshot.long_id === 'Current State')
            }
        },
        showOrgMenu() {
            if (this.orgNames.length > 0) {
                return true
            } else {
                return false
            }
        },
        showSpaceMenu() {
            if (this.spacesWithPrimarySnapshots.length && this.fetchingSpaces === false) {
                return true
            } else {
                return false
            }
        },
        showInstanceMenu() {
            if (this.fetchingInstances === false && this.fetchingSpaces === false && this.$route.params.snid !== undefined) {
                return true
            } else {
                return false
            }
        },
        showSnapshotMenu() {
            if (this.instanceSnapshots.length && this.fetchingSnapshots === false && this.fetchingInstances === false && this.fetchingSpaces === false) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        organizationMenu: function(val) {
            if (val === true) {
                this.$data.organizationSearch = ''
            }
        },
        spaceMenu: function(val) {
            if (val === true) {
                this.$data.spaceSearch = ''
            }
        },
        instanceMenu: function(val) {
            if (val === true) {
                this.$data.instanceSearch = ''
            }
        },
        snapshotMenu: function(val) {
            if (val === true) {
                this.$data.snapshotSearch = ''
            }
        }
    }
}
